<script>

export default {
  name: "Sound",
  data() {
    return {
      stt:null,
      fonte:null,
      recordAudio: null,
      audio: null,
      recorder: null,
      timerStart: null,
      elapsedTimeTimer: null,
      audioElement: null, //Não é o audio em si, mas o elemento HTML que o reproduz
      audioElementSource: null, //Esse é a fonte do audio que o elemento HTML usa
      audioBlobi:null,
      audioRecorder: {
        audioBlobs: [],
        mediaRecorder: null,
        streamBeingCaptured: null,
      }
    };
  },
  mounted() {
    //FUNÇÕES PRONTAS, PRECISO VER COMO TRABALHAR COM AS FUNÇÕES DENTRO DO OBJETO audioRecorder
  },
  methods: {
    async start() {
      if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
        return Promise.reject(new Error('mediaDevices API or getUserMedia method is not supported in this browser.'));
      } else {
        return navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {

          this.audioRecorder.streamBeingCaptured = stream;
          console.log('At the beggining of start... this.audioRecorder.streamBeingCaptured:')
          console.log(this.audioRecorder.streamBeingCaptured)

          this.audioRecorder.mediaRecorder = new MediaRecorder(stream);
          console.log('At the middle of start... this.audioRecorder.streamBeingCaptured:')
          console.log(this.audioRecorder.streamBeingCaptured)

          this.audioRecorder.audioBlobs = []

          this.audioRecorder.mediaRecorder.addEventListener("dataavailable", event => {
            //store audio Blob object
            this.audioRecorder.audioBlobs.push(event.data);
          });

          console.log(this.audioRecorder.audioBlobs)
          //start the recording by calling the start method on the media recorder
          this.audioRecorder.mediaRecorder.start();
          console.log(this.audioRecorder)
          console.log(this.audioRecorder.mediaRecorder)
        })
      }
    },
    async stop() {
      return new Promise((resolve) => {
        //save audio type to pass to set the Blob type
        // let mimeType = this.audioRecorder.mediaRecorder.mimeType;

        //listen to the stop event in order to create & return a single Blob object
        this.audioRecorder.mediaRecorder.addEventListener("stop", () => {
          //create a single blob object, as we might have gathered a few Blob objects that needs to be joined as one
          let audioBlob = new Blob(this.audioRecorder.audioBlobs, { type:'audio/wav' });
          console.log(audioBlob)
          //resolve promise with the single audio blob representing the recorded audio
          resolve(audioBlob);
        });
         this.cancel();
      });
    },
    cancel() {
      //stop the recording feature
       this.audioRecorder.mediaRecorder.stop();

      //stop all the tracks on the active stream in order to stop the stream
       this.stopStream();

      //reset API properties for next recording
      this.resetRecordingProperties();
    },
    async stopStream() {
      //stopping the capturing request by stopping all the tracks on the active stream
      this.audioRecorder.streamBeingCaptured.getTracks() //get all tracks from the stream
        .forEach(track /*of type MediaStreamTrack*/ => track.stop()); //stop each one
    },
    /** Reset all the recording properties including the media recorder and stream being captured*/
    resetRecordingProperties() {
      this.audioRecorder.mediaRecorder = null;
      this.audioRecorder.streamBeingCaptured = null;
    },
    async StartRec() {
      console.log("Recording Audio...");

      //If a previous audio recording is playing, pause it
      console.log("Is there a previous audio?...");
      console.log(this.audioElement)
      if (this.audioElement) {
        let recorderAudioIsPlaying = !this.audioElement.paused; // the paused property tells whether the media element is paused or not
        console.log("paused?", recorderAudioIsPlaying);
        if (recorderAudioIsPlaying) {
          console.log("Pausing Previous audio...");
          console.log(this.audioElement)
          this.audioElement.pause();
        }
      }

      //start recording using the audio recording API
      await this.start()
        .then((veio) => {
          console.log('veio: ' + veio)
          console.log('b4 timestart')
          console.log(this.audioRecorder)
          this.timerStart = new Date()
        }).catch(error => { //on error
          //No Browser Support Error
          if (error.message.includes("mediaDevices API or getUserMedia method is not supported in this browser.")) {
            console.log("To record audio, use browsers like Chrome and Firefox.");
            // displayBrowserNotSupportedOverlay();
          }
          //Error handling structure
          switch (error.name) {
            case 'AbortError': //error from navigator.mediaDevices.getUserMedia
              console.log("An AbortError has occured.");
              break;
            case 'NotAllowedError': //error from navigator.mediaDevices.getUserMedia
              console.log("A NotAllowedError has occured. User might have denied permission.");
              break;
            case 'NotFoundError': //error from navigator.mediaDevices.getUserMedia
              console.log("A NotFoundError has occured.");
              break;
            case 'NotReadableError': //error from navigator.mediaDevices.getUserMedia
              console.log("A NotReadableError has occured.");
              break;
            case 'SecurityError': //error from navigator.mediaDevices.getUserMedia or from the MediaRecorder.start
              console.log("A SecurityError has occured.");
              break;
            case 'TypeError': //error from navigator.mediaDevices.getUserMedia
              console.log("A TypeError has occured.");
              break;
            case 'InvalidStateError': //error from the MediaRecorder.start
              console.log("An InvalidStateError has occured.");
              break;
            case 'UnknownError': //error from the MediaRecorder.start
              console.log("An UnknownError has occured.");
              break;
            default:
              console.log("An error occured with the error name " + error.name);
              console.log(error)
          }
        })

    },
    async StopRec() {
      console.log("Stopping Audio Recording...");

      //stop the recording using the audio recording API
      await this.stop()
        .then(async audioAsBlob => {
          // let url = 'https://speech-text-test.aihuk.com/audio'
          // let nB = { 'audioAsBlob': audioAsBlob,}
          // fetch(url, {
          //   'mode':'no-cors',
          //   'Content-Type': 'multipart/form-data',
          //   'method': 'POST',
          //   'body': JSON.stringify(nB),
          // })
          // .then((res) => res.json())
          // .then((content) => {
          //   console.log('Fetch Content:' + content)
          //   console.log('Audio no fim de stop: ' + audioAsBlob)
          // })
          //Play recorder audio
          await this.playRec(audioAsBlob);
        })
        .catch(error => {
          //Error handling structure
          switch (error.name) {
            case 'InvalidStateError': //error from the MediaRecorder.stop
              console.log("An InvalidStateError has occured.");
              break;
            default:
              console.log("An error occured with the error name " + error.name);
              console.log(error)
          }
        })
    },
    async playRec(audioAsBlob) {
      let reader = new FileReader();
      this.audioBlobi = audioAsBlob
      reader.onload = async (e) => {
        console.log('Cheguei depos?')
        //store the base64 URL that represents the URL of the recording audio
        let base64URL = e.target.result;
        //If this is the first audio playing, create a source element
        //as pre populating the HTML with a source of empty src causes error
        if (!this.audioElementSource) //if its not defined create it (happens first time only)
        await this.createSourceForAudioElement();
      
      //set the audio element's source using the base64 URL
      this.audioElementSource.src = base64URL;
      this.fonte = base64URL;
      
      //set the type of the audio element based on the recorded audio's Blob type
      let BlobType = audioAsBlob.type.includes(";") ?
      audioAsBlob.type.substr(0, audioAsBlob.type.indexOf(';')) : audioAsBlob.type;
      this.audioElementSource.type = BlobType
      
      //call the load method as it is used to update the audio element after changing the source or other settings
      console.log("Loading audio...");
      console.log(this.audioElement)
      this.audioElement.load();
      
      //play the audio after successfully setting new src and type that corresponds to the recorded audio
      console.log("Playing audio...");
      console.log(JSON.stringify(this.audioElement.src))
      this.audioElement.play();


      console.log('e.target.result: '+e.target.result)
      console.log('this.audioElementSource: '+JSON.stringify(this.audioElementSource))
      console.log('base64URL: '+base64URL)
      console.log('audioAsBlob: '+audioAsBlob)
      console.log('this.audioBlobi: '+this.audioBlobi)
      
      let url = 'https://speech-to-text.aihuk.com/audio'
      fetch(url, {
        // 'mode':'no-cors',
        // 'Content-Type': 'multipart/form-data',
        headers: new Headers({
          "content-type": "audio/wav",
        }),
        'method': 'POST',
        'body':audioAsBlob,
      })
      .then((res) => res.json())
      .then((content) => {
        console.log('Fetch Content:')
        console.log(content)
        this.stt = content.transcript
        console.log(this.stt)
        console.log('Audio no fim de stop: ')
        console.log(audioAsBlob)
        console.log(this.audioElement.src)
      })
      }
      console.log('cheguei antes?')
      reader.readAsDataURL(audioAsBlob);
    },
    async CancelRec() {
      console.log("Canceling audio...");

      //cancel the recording using the audio recording API
      await this.cancel();
    },
    async createSourceForAudioElement() {
      this.audioElement = this.$refs.audioE;
      console.log(this.audioElement)
      console.log('createSource')
      let sourceElement = document.createElement("source");
      console.log(sourceElement)
      this.audioElement.appendChild(sourceElement);
      console.log(this.audioElement)
      console.log("Apending source to audio...");

      this.audioElementSource = sourceElement;
      // console.log(this.audioElementSource.src)
    },
    // elapsedRecTime() {
    //   displayElapsedTimeDuringAudioRecording("00:00");
    //   this.elapsedTimeTimer = setInterval(() => {
    //     let elapsedTime = computeElapsedTime(this.timerStart);
    //     displayElapsedTimeDuringAudioRecording(elapsedTime);
    //   }, 1000);
    // },
    // computeElapsedTime(startTime) {
    //   let endTime = new Date();
    //   let timeDiff = endTime - startTime;
    //   timeDiff = timeDiff / 1000;
    //   let seconds = Math.floor(timeDiff % 60);
    //   seconds = seconds < 10 ? "0" + seconds : seconds;
    //   timeDiff = Math.floor(timeDiff / 60);
    //   let minutes = timeDiff % 60;
    //   minutes = minutes < 10 ? "0" + minutes : minutes;

    //   timeDiff = Math.floor(timeDiff / 60);
    //   let hours = timeDiff % 24;
    //   timeDiff = Math.floor(timeDiff / 24);
    //   let days = timeDiff;

    //   let totalHours = hours + (days * 24);
    //   totalHours = totalHours < 10 ? "0" + totalHours : totalHours;

    //   if (totalHours === "00") {
    //     return minutes + ":" + seconds;
    //   } else {
    //     return totalHours + ":" + minutes + ":" + seconds;
    //   }
    // },
    enviarRec() {
      console.log(this.audioElement.src)
      // let url = 'https://speech-text-test.aihuk.com/audio'
      // let nB = { 'audio2': this.audioElement }
      // fetch(url, {
      //   'Content-Type': 'multipart/form-data',
      //   'mode':'no-cors',
      //   'method': 'POST',
      //   'body': JSON.stringify(nB),
      // })
      //   .then((res) => res.json())
      //   .then((content) => {
      //     console.log('Fetch Content:' + content)
      //     console.log('Audio no fim de stop: ' + this.audioElement)
      //   })
    },
  },
}
</script>

<template>
  <main>
    <div class="container">
      <button class='button' @click="StartRec">Start</button>
      <button class='button' @click="StopRec">Pause</button>
      <button class='button' @click="enviarRec">Send</button>
      <br>
      <p ref='time' class="elapsed-time"></p>
      <br><br>
      <audio controls ref="audioE"></audio>
      <br><br>
      <br><br>
      <p>{{ stt }}</p>
    </div>
  </main>
</template>

<style scoped>
main {
  width: 100vw;
  max-width: 100%;
  height: max;
  background-color: #242424;

  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  width: 600px;
  height: 600px;
  padding: 20px;
  border-radius: 16px;
  background-color: #fff;
}

.container svg {
  /* width: 100%;
    height: 100%; */
  background-color: #cccccc;
  height: max-content;
  width: max-content;
}

.modal {
  background-color: red;
}
</style>
